import React from "react";
import get from "lodash/get";

import { graphql } from "gatsby";

import Layout from "../components/global/layout";
import Container from "../components/global/container";
import InnerPageHeader from "../components/inner-page-header/inner-page-header";

class Disclaimer extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;

        return (
            <Layout
                title={`Protocol Disclaimer | ${meta.siteMetadata.title}`}
                location={location}
            >
                <InnerPageHeader pageTitle="Protocol Disclaimer" />
                <article>
                    <section className="py-60 background-black text-white content">
                        <Container>
                            <div className="row">
                                <div className="col-12 col-md-10 offset-md-1">
                                    <p>
                                        Unifyd is a decentralized yield generation
                                        protocol that people can use to invest
                                        in DeFi investment products. Currently,
                                        There is one version of the Unifyd protocol
                                        (v1), made up of free, public,
                                        open-source or source-available software
                                        including a set of smart contracts that
                                        are deployed on the Ethereum Blockchain
                                        and Binance Smart Chain. Your use of the
                                        Unifyd protocol involves various risks,
                                        including, but not limited to, losses
                                        while digital assets are being supplied
                                        to the Unifyd protocol and losses due to
                                        the fluctuation of prices of tokens in a
                                        vault, trading pair, liquidity pool,
                                        lending contracts or any other smart
                                        contract. Before using the Unifyd protocol,
                                        you should review the relevant
                                        documentation to make sure you
                                        understand how the Unifyd protocol works.
                                        Additionally, just as you can access
                                        email protocols such as SMTP through
                                        multiple email clients, you can access
                                        the Unifyd protocol through dozens of web
                                        or mobile interfaces. You are
                                        responsible for doing your own diligence
                                        on those interfaces to understand the
                                        fees and risks they present.
                                    </p>
                                    <p>
                                        AS DESCRIBED IN THE UNIFYD PROTOCOL
                                        LICENSES, THE UNIFYD PROTOCOL IS PROVIDED
                                        ”AS IS”, AT YOUR OWN RISK, AND WITHOUT
                                        WARRANTIES OF ANY KIND. Honest Labs Inc.
                                        d/b/a/ ”Honest Labs” ( ”HonestLabs Labs”
                                        ) developed much of the initial code for
                                        the Unifyd protocol, it does not provide,
                                        own, or control the Unifyd protocol, which
                                        is run by smart contracts deployed on
                                        the Ethereum blockchain or Binance smart
                                        chain. Upgrades and modifications to the
                                        protocol are managed in a
                                        community-driven way by holders of the
                                        ALGO governance token. No developer or
                                        entity involved in creating the Unifyd
                                        protocol will be liable for any claims
                                        or damages whatsoever associated with
                                        your use, inability to use, or your
                                        interaction with other users of, the Unifyd
                                        protocol, including any direct,
                                        indirect, incidental, special,
                                        exemplary, punitive or consequential
                                        damages, or loss of profits,
                                        cryptocurrencies, tokens, or anything
                                        else of value.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
            </Layout>
        );
    }
}

export default Disclaimer;

export const DisclaimerQuery = graphql`
    query Disclaimer {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;
